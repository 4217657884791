import { Add, Clear, NotificationsActive, NotificationsNoneOutlined } from '@mui/icons-material';
import StockIcon from '@mui/icons-material/ShowChartRounded';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import moment from 'moment';
import { Component } from 'react';
import './App.css';
import { Ticker } from './services';

interface State {
}

interface Props {
    ticker: Ticker,
    earningsDate?: moment.Moment | null,
    onDelete: (tickerCode: string) => void,
    onSave: (tickerCode: string) => void,
    onToggleAlert?: (tickerCode: string) => void,
    star: boolean,
    notify: boolean,
    showNotify: boolean,
}

class SingleTicker extends Component<Props, State> {

    getEarningsText(tickerCode: string) {
        const time = this.props.earningsDate;
        return time ? `${time.isAfter(moment()) ? 'Next' : 'Last'} earnings: ${time.format("MMM Do")}`
            : 'Loading...';
    }

    render() {
        const ticker = this.props.ticker;
        return (
            <ListItem
                sx={{
                    backgroundColor: this.props.star ? 'darkgreen' : '#242424',
                    marginBottom: '5px',
                }}
                secondaryAction={
                    <div>
                        {this.props.showNotify ?
                            <IconButton edge="end" aria-label="delete"
                                onClick={() => this.props.onToggleAlert ? this.props.onToggleAlert(ticker.ticker) : null}
                                sx={{ marginRight: 0 }}
                            >
                                {this.props.notify ? <NotificationsActive /> : <NotificationsNoneOutlined />}
                            </IconButton> : null}
                        {this.props.star ?
                            <IconButton edge="end" aria-label="delete" onClick={() => { this.props.onDelete(ticker.ticker) }}>
                                <Clear />
                            </IconButton> :
                            <IconButton edge="end" aria-label="add" onClick={() => { this.props.onSave(ticker.ticker) }}>
                                <Add />
                            </IconButton>}
                    </div>
                }
            >
                <ListItemAvatar><StockIcon /></ListItemAvatar>
                <ListItemText
                    primary={ticker.ticker.toUpperCase()}
                    secondary={<i>{this.getEarningsText(ticker.ticker)}</i>}
                />
            </ListItem>);
    }
}

export default SingleTicker;
import { Link } from '@mui/material';
import Container from '@mui/material/Container';
import Drawer from '@mui/material/Drawer';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { useState } from 'react';

interface Props {
    signUpState: 'NO_PROMPT' | 'PROMPT_SIGN_IN' | 'WAITING_FOR_CONFIRMATION';
    onClose: () => void;
    onTryAgain: () => void;
    loginWithEmail: (email: string) => void;
}


function BottomDrawer(props: Props) {
    const [email, setEmail] = useState('');
    return (
        <div>
            <Drawer
                anchor="bottom"
                open={props.signUpState === 'PROMPT_SIGN_IN'}
                onClose={(e) => props.onClose()}
            >
                <Container
                    sx={{
                        paddingTop: '10px',
                        paddingBottom: '10px',
                    }}>
                    <Typography variant="body1" component="div">
                        Sign up to save stocks and get calendar notifications.
                    </Typography>
                    <TextField
                        value={email}
                        autoComplete='email'
                        placeholder="Email Address"
                        variant="outlined"
                        sx={{
                            marginTop: '10px',
                            width: '100%',
                        }}
                        onChange={e => setEmail(e.target.value)}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                props.loginWithEmail(email);
                            }
                        }}
                    />
                    <Typography variant="subtitle2" component="div"
                        sx={{
                            marginTop: '10px',
                            textAlign: 'center'
                        }}>
                        Existing user? Enter your email to log back in.
                    </Typography>
                </Container>
            </Drawer>

            <Drawer
                anchor="bottom"
                open={props.signUpState === 'WAITING_FOR_CONFIRMATION'}
                onClose={(e) => props.onClose()}
            >
                <Container
                    sx={{
                        paddingTop: '10px',
                        paddingBottom: '10px',
                    }}>
                    <Typography variant="h6" component="div" sx={{ marginBottom: '10px' }}>
                        Check your email for a sign-in link.
                    </Typography>
                    <Typography variant="subtitle2" component="div">
                        Didn't receive a link?&nbsp;
                        <Link onClick={(e) => { props.onTryAgain() }}>Try again</Link>
                    </Typography>
                </Container>
            </Drawer>
        </div>
    );
}

export default BottomDrawer
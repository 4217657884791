import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';

interface Props {
    signInState: 'NOT_SIGNED_IN' | 'SIGNED_IN' | 'SIGNED_IN_ANON';
    onSignIn: () => void;
    onSignOut: () => void;
}

function AppHeader(props: Props) {
    return (
        <Box>
            <Box sx={{ flexGrow: 1 }}>
                <AppBar position="static">
                    <Toolbar sx={{
                        background: props.signInState === 'SIGNED_IN' ? 'darkgreen' : null
                    }}>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            FinanceCal
                        </Typography>
                        {props.signInState === 'SIGNED_IN' ?
                            <Button color="inherit" onClick={() => props.onSignOut()}>Logout</Button> :
                            <Button color="inherit" onClick={() => props.onSignIn()}>Login</Button>}
                    </Toolbar>
                </AppBar>
            </Box>
            {props.signInState !== 'SIGNED_IN' ? <Box
                sx={{
                    paddingTop: '30px',
                    paddingBottom: '30px',
                    backgroundColor: 'darkgreen',
                    color: 'white',
                    fontSize: '38px',
                    textAlign: 'center',
                    fontWeight: 'bold',
                }}>
                Public market events, for your stocks, on your calendar.
            </Box> : null}
        </Box>
    );
}
export default AppHeader;
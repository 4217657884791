import { NotificationsNoneOutlined } from '@mui/icons-material';
import { Grid, IconButton, Paper } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import assert from 'assert';
import { FirebaseError } from 'firebase/app';
import {
  EmailAuthProvider,
  getAuth, isSignInWithEmailLink, linkWithCredential, sendSignInLinkToEmail, signInAnonymously, signInWithCredential, signOut,
  Unsubscribe,
  User
} from 'firebase/auth';
import { Component } from 'react';
import { db } from './admin';
import './App.css';
import AppHeader from './app_header';
import SavedTickers from './saved_tickers';
import Search from './search';
import { Favorites, getFavorites, lookupTickers, setFavorites, Ticker } from './services';
import SignupDrawer from './signup_drawer';
import TickerPreview from './ticker_preview';

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#228B22',
      light: '#90EE90',
      dark: '#006400',
    },
    info: {
      main: '#228B22',
      light: '#90EE90',
      dark: '#006400',
    },
  },
});

const POPULAR_TICKERS = [
  "AAPL",
  "MSFT",
  "AMZN",
  "TSLA",
  "GOOG",
  "NVDA",
  "META",
  "UNH",
  "JNJ",
  "JPM",
  "V",
  "PG",
  "XOM",
  "HD",
  "CVX",
  "MA",
  "BAC",
  "ABBV",
  "PFE",
  "AVGO",
  "COST",
  "DIS",
  "KO",
].sort(() => (Math.random() > .5) ? 1 : -1).map(x => x.toLowerCase());

interface AppProps {
}

interface AppState {
  signInState: 'NOT_SIGNED_IN' | 'SIGNED_IN' | 'SIGNED_IN_ANON';
  signUpState: 'NO_PROMPT' | 'PROMPT_SIGN_IN' | 'WAITING_FOR_CONFIRMATION';
  savedTickers: Map<string, Ticker>,
  alertingTickerCodes: Set<string>,
  firebaseUser?: User | null,
}

const waitForUserChange = function () {
  return new Promise<User | null>((resolve, reject) => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged(user => {
      resolve(user);
      unsubscribe();
    });
  });
};

class App extends Component<AppProps, AppState> {
  unregisterFirebaseListener?: Unsubscribe;
  favorites: Favorites;
  popularTickers: Ticker[];
  constructor(props: AppProps) {
    super(props);
    this.state = {
      signInState: 'NOT_SIGNED_IN',
      signUpState: 'NO_PROMPT',
      savedTickers: new Map<string, Ticker>(),
      alertingTickerCodes: new Set<string>(),
    }
    this.handleSignOut = this.handleSignOut.bind(this);
    this.handleSaveTicker = this.handleSaveTicker.bind(this);
    this.handleDeleteTicker = this.handleDeleteTicker.bind(this);
    this.handleToggleAlerting = this.handleToggleAlerting.bind(this);

    this.favorites = { alerting: [], tickers: [] };

    this.popularTickers = [];
  }

  async logInWithEmail(email: string) {
    const port = (window.location.port === '3000') ? ':3000' : '';
    const actionCodeSettings = {
      url: `${window.location.protocol}//${window.location.hostname}${port}`,
      // This must be true.
      handleCodeInApp: true,
    };
    this.setState({ signUpState: 'WAITING_FOR_CONFIRMATION' });
    try {
      await sendSignInLinkToEmail(getAuth(), email, actionCodeSettings);
      window.localStorage.setItem('emailForSignIn', email);
    } catch (e) {
      // TODO(danieljy): Butterbar with error.
      console.log('error', e);
    }
  }

  async handleSaveTicker(ticker: Ticker) {
    // If we don't know of the user yet, now is the time to trigger and wait on an anonymous account creation.
    if (!this.state.firebaseUser) {
      const auth = getAuth();
      try {
        const creds = await signInAnonymously(auth);
        this.setState({ firebaseUser: creds.user, signInState: 'SIGNED_IN_ANON' });
      } catch (e) {
        console.error(e);
      }
    }
    assert(!!this.state.firebaseUser)
    const savedTickers = this.state.savedTickers;
    savedTickers.set(ticker.ticker, ticker);
    this.favorites.tickers = [...savedTickers.keys()]
    await setFavorites(db, this.state.firebaseUser, this.favorites);
    this.setState({ savedTickers });
  }

  async handleDeleteTicker(tickerCode: string) {
    assert(!!this.state.firebaseUser)
    const savedTickers = this.state.savedTickers;
    const alertingTickerCodes = this.state.alertingTickerCodes;
    savedTickers.delete(tickerCode);
    alertingTickerCodes.delete(tickerCode);
    this.favorites.tickers = [...savedTickers.keys()]
    this.favorites.alerting = [...alertingTickerCodes];
    await setFavorites(db, this.state.firebaseUser, this.favorites);
    this.setState({ savedTickers });
  }

  async handleToggleAlerting(tickerCode: string) {
    assert(!!this.state.firebaseUser)
    if (this.state.signInState === 'SIGNED_IN_ANON') {
      this.setState({ signUpState: 'PROMPT_SIGN_IN' })
      return;
    }
    const alertingTickerCodes = this.state.alertingTickerCodes;
    if (alertingTickerCodes.has(tickerCode)) {
      alertingTickerCodes.delete(tickerCode);
    } else {
      alertingTickerCodes.add(tickerCode);
    }
    this.favorites.alerting = [...alertingTickerCodes];
    await setFavorites(db, this.state.firebaseUser, this.favorites);
    this.setState({ alertingTickerCodes });
  }

  async componentDidMount() {
    this.popularTickers = await lookupTickers(db, POPULAR_TICKERS);

    // Get current user.
    let firebaseUser = await waitForUserChange();
    const auth = getAuth();

    if (isSignInWithEmailLink(auth, window.location.href)) {
      // TODO(danieljy): Make this prettier.
      const email = window.localStorage.getItem('emailForSignIn') ||
        prompt('Please confirm your email address to finish creating your account.') ||
        '';
      const credential = EmailAuthProvider.credentialWithLink(
        email, window.location.href);

      const location = window.location;
      const port = location.port !== '80' ? `:${location.port}` : '';
      window.history.replaceState({}, '', `//${location.hostname}${port}${location.pathname}`);

      try {
        if (firebaseUser && firebaseUser.isAnonymous) {
          // If we have a current (anonymous) user, we just link it to the credentials.
          try {
            await linkWithCredential(firebaseUser, credential);
          } catch (e) {
            // There is a very specific case that we need to catch, which is an existing user
            // that created an anonymous account by accident.
            if (e instanceof FirebaseError && e.code === 'auth/email-already-in-use') {
              const { user } = await signInWithCredential(auth, credential);
              firebaseUser = user;
            } else {
              throw e;
            }
          }
        } else {
          // New/existing account being so proceed with that.
          const { user } = await signInWithCredential(auth, credential);
          firebaseUser = user;
        }
      } catch (e) {
        // Some other error that we can't recover from.
        // Don't do anything, since we'll just maintain the current state and the user
        // can try again if they are anonymously logged in.
        console.error(e);
      }
      // Clean up the url.
    }

    if (firebaseUser) {
      this.state.savedTickers.clear();
      this.state.alertingTickerCodes.clear();
      this.favorites = await getFavorites(db, firebaseUser);
      this.favorites.tickers.forEach(
        // Fake a ticker for now, since we don't need the name.
        tickerCode => this.state.savedTickers.set(tickerCode, { ticker: tickerCode, name: '' }));
      this.favorites.alerting.forEach(v => this.state.alertingTickerCodes.add(v));
      this.setState({
        firebaseUser,
        signInState: firebaseUser.isAnonymous ? 'SIGNED_IN_ANON' : 'SIGNED_IN',
        signUpState: 'NO_PROMPT',
      });
    } else {
      this.setState({
        firebaseUser
      });
    }
  }

  componentWillUnmount() {
    if (this.unregisterFirebaseListener) {
      this.unregisterFirebaseListener();
    }
  }

  async handleSignOut() {
    await signOut(getAuth());
    this.state.savedTickers.clear();
    this.state.alertingTickerCodes.clear();
    this.setState({
      firebaseUser: null,
      signInState: 'NOT_SIGNED_IN', signUpState: 'NO_PROMPT'
    });
  }

  render() {
    // // The call to get the firebase user is async, so we wait on it to render.
    if (this.state.firebaseUser === undefined) {
      return (
        <ThemeProvider theme={theme}>
          <Paper elevation={0} sx={{ minHeight: '100vh', paddingBottom: '20px' }}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              minHeight="100vh"
            >
              <div className="loader"></div>
            </Box>
          </Paper>
        </ThemeProvider>);
    }

    return (
      <ThemeProvider theme={theme} >
        <Paper elevation={0} sx={{ minHeight: '100vh' }}>
          <AppHeader
            signInState={this.state.signInState}
            onSignIn={() => this.setState({ signUpState: 'PROMPT_SIGN_IN' })}
            onSignOut={this.handleSignOut} />
          <Container
            sx={{
              paddingTop: '10px',
            }}>
            <Typography variant="h6" component="div">
              Search by ticker or company
            </Typography>
            <Box sx={{ paddingTop: '10px', paddingBottom: '10px' }}>
              <Paper>
                <Search onSelectTicker={this.handleSaveTicker}
                  previewTickers={this.popularTickers} />
              </Paper>
            </Box>
            <TickerPreview
              ticker={null}
              previewTickers={this.popularTickers}
              onSave={this.handleSaveTicker}
            ></TickerPreview>
            <Typography variant="h6" component="div">
              Your stock list
            </Typography>
            <SavedTickers tickers={this.state.savedTickers}
              alerting={this.state.alertingTickerCodes}
              onDelete={this.handleDeleteTicker}
              onSave={this.handleSaveTicker}
              onToggleAlert={this.handleToggleAlerting}>
            </SavedTickers>
            {(this.state.savedTickers.size && this.state.signInState === 'SIGNED_IN_ANON') ?
              <Grid container justifyContent="center"
                sx={{ paddingTop: '25px' }}>
                <Button variant="outlined"
                  onClick={() => this.setState({ signUpState: 'PROMPT_SIGN_IN' })}
                >
                  <IconButton edge="end" aria-label="delete">
                    <NotificationsNoneOutlined color="primary">
                    </NotificationsNoneOutlined>
                  </IconButton>
                  <Box sx={{ marginLeft: '10px' }}>Get Calendar Notifications</Box>
                </Button>
              </Grid> : null}
          </Container>
          <SignupDrawer
            signUpState={this.state.signUpState}
            onClose={() => this.setState({ signUpState: 'NO_PROMPT' })}
            onTryAgain={() => this.setState({ signUpState: 'PROMPT_SIGN_IN' })}
            loginWithEmail={(email) => this.logInWithEmail(email)}
          />
        </Paper>
      </ThemeProvider >
    );
  }
}

export default App;

import { Autocomplete, TextField } from "@mui/material";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { Component } from "react";
import { db } from './admin';
import { getTickersMatchingPrefix, Ticker } from "./services";

interface TickerOption extends Ticker {
  label: string,
}

interface Props {
  previewTickers: Ticker[],
  onSelectTicker: (ticker: Ticker) => {},
}

interface State {
  currentSelection: any,
  options: TickerOption[];
}


class Search extends Component<Props, State> {
  private previewOptions: TickerOption[] = [];
  private getMatchingTickersDebounced: any;
  constructor(props: Props) {
    super(props);
    this.state = {
      currentSelection: null,
      options: [],
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleNewPrefix = this.handleNewPrefix.bind(this);
    this.getMatchingTickers = this.getMatchingTickers.bind(this);
    this.getMatchingTickersDebounced = AwesomeDebouncePromise(this.getMatchingTickers, 150);
  }

  componentDidMount() {
    this.previewOptions = this.props.previewTickers.map(x => {
      return {
        name: x.name,
        label: `${x.ticker.toUpperCase()} (${x.name})`,
        ticker: x.ticker,
      } as TickerOption;
    }).sort((a, b) => a.ticker > b.ticker ? 1 : -1);
    this.setState({ options: this.previewOptions });
  }

  handleChange(e: any, selectedTicker: TickerOption | null) {
    if (!selectedTicker) {
      return;
    }
    this.props.onSelectTicker({ ticker: selectedTicker.ticker, name: selectedTicker.name });
    this.setState({ currentSelection: null });
  }

  async getMatchingTickers(prefix: string) {
    if (prefix.length === 0) {
      return this.previewOptions;
    }

    const matches = await getTickersMatchingPrefix(db, prefix);
    return matches.map(x => {
      return {
        name: x.name,
        label: `${x.ticker.toUpperCase()} (${x.name})`,
        ticker: x.ticker,
      } as TickerOption;
    });
  }

  async handleNewPrefix(e: any, prefix: string, type: string) {
    const options = await this.getMatchingTickersDebounced(prefix);
    this.setState({ options });
  }

  render() {
    return <Autocomplete
      clearOnBlur={true}
      autoHighlight
      blurOnSelect
      value={this.state.currentSelection}
      onChange={this.handleChange}
      onInputChange={this.handleNewPrefix}
      popupIcon={""}
      options={this.state.options}
      filterOptions={(e) => e}
      renderInput={(params) => <TextField
        placeholder="Ticker or Company"
        type="search"
        {...params}
      />}
    />
  }

}

export default Search;
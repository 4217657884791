import List from '@mui/material/List';
import moment from 'moment';
import { Component } from 'react';
import Carousel from 'react-material-ui-carousel';
import { db } from './admin';
import './App.css';
import { getLatestEarningsDate, Ticker } from './services';
import SingleTicker from './single_ticker';

interface State {
    nextEarningDates: Map<string, moment.Moment | null>;
}

interface Props {
    ticker: Ticker | null,
    previewTickers: Ticker[],
    onSave: (ticker: Ticker) => void,
}

class TickerPreview extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            nextEarningDates: new Map<string, moment.Moment>(),
        }
    }

    async getTickerAndEarningsDate(tickerCode: string): Promise<[string, moment.Moment | null]> {
        return [tickerCode, await getLatestEarningsDate(db, tickerCode)];
    }

    async componentDidMount() {
        this.componentDidUpdate();
    }

    async componentDidUpdate() {
        let allTickers = this.props.previewTickers;
        if (this.props.ticker) {
            allTickers = this.props.previewTickers.concat([this.props.ticker]);
        }

        const promises = [];
        for (const ticker of allTickers.values()) {
            const tickerCode = ticker.ticker;
            if (!this.state.nextEarningDates.has(tickerCode)) {
                promises.push(this.getTickerAndEarningsDate(tickerCode));
            }
        }
        if (promises.length > 0) {
            const results = await Promise.all(promises);
            for (const [ticker, result] of results) {
                this.state.nextEarningDates.set(ticker, result);
            }
            // Keep protected in condition.
            this.forceUpdate();
        }
    }

    getEarningsText(tickerCode: string) {
        const nextTime = this.state.nextEarningDates.get(tickerCode);
        return (
            nextTime ?
                `Next earnings: ${nextTime.format("MMM Do")}` :
                'Loading...');
    }

    singleTicker(ticker: Ticker) {
        return (
            <List dense={true} key={ticker.ticker}>
                <SingleTicker ticker={ticker}
                    earningsDate={this.state.nextEarningDates.get(ticker.ticker)}
                    onDelete={() => { }}
                    onSave={() => { this.props.onSave(ticker) }}
                    star={false}
                    notify={false}
                    showNotify={false}
                ></SingleTicker>
            </List>);
    }

    render() {
        return (
            <div>
                {this.props.ticker ? this.singleTicker(this.props.ticker) :
                    <Carousel
                        stopAutoPlayOnHover={false}
                        animation='fade'
                        indicators={false}
                        swipe={false}
                        navButtonsAlwaysInvisible={true}
                        duration={1000}
                        interval={10000}
                    >
                        {this.props.previewTickers.map((t) => this.singleTicker(t))}
                    </Carousel>}
            </div>);
    }
}

export default TickerPreview;
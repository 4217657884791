
import { Error } from '@mui/icons-material';
import Grow from '@mui/material/Grow';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import moment from 'moment';
import { Component } from 'react';
import { TransitionGroup } from 'react-transition-group';
import { db } from './admin';
import './App.css';
import { getLatestEarningsDate, Ticker } from './services';
import SingleTicker from './single_ticker';

interface State {
    nextEarningDates: Map<string, moment.Moment | null>;
}

interface Props {
    tickers: Map<string, Ticker>,
    alerting: Set<string>,
    onDelete: (tickerCode: string) => void,
    onSave: (ticker: Ticker) => void,
    onToggleAlert: (tickerCode: string) => void,
}

class SavedTickers extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            nextEarningDates: new Map<string, moment.Moment>(),
        }
    }

    async getTickerAndEarningsDate(tickerCode: string): Promise<[string, moment.Moment | null]> {
        return [tickerCode, await getLatestEarningsDate(db, tickerCode)];
    }

    async componentDidMount() {
        const promises = [];
        for (const ticker of this.props.tickers.values()) {
            const tickerCode = ticker.ticker;
            if (!this.state.nextEarningDates.has(tickerCode)) {
                promises.push(this.getTickerAndEarningsDate(tickerCode));
            }
        }
        if (promises.length > 0) {
            const results = await Promise.all(promises);
            for (const [ticker, result] of results) {
                this.state.nextEarningDates.set(ticker, result);
            }
            // Keep protected in condition.
            this.forceUpdate();
        }
    }

    async componentDidUpdate(prevProps: Props) {
        this.componentDidMount();
        if (prevProps.alerting.size !== this.props.alerting.size) {
            this.forceUpdate();
        }
    }

    render() {
        const savedTickers = [];
        for (const ticker of this.props.tickers.values()) {
            const earningsDate = this.state.nextEarningDates.get(ticker.ticker);
            savedTickers.push(
                <Grow key={ticker.ticker}>
                    <div>
                        <SingleTicker ticker={ticker} earningsDate={earningsDate}
                            onDelete={(tickerCode) => this.props.onDelete(tickerCode)}
                            onSave={(tickerCode) => this.props.onSave(ticker)}
                            onToggleAlert={(tickerCode) => this.props.onToggleAlert(tickerCode)}
                            star={true}
                            notify={this.props.alerting.has(ticker.ticker)}
                            showNotify={true}
                        ></SingleTicker>
                    </div>
                </Grow>);
        }
        savedTickers.reverse();

        return (
            savedTickers.length ?
                <List dense={true}>
                    <TransitionGroup>
                        {savedTickers}
                    </TransitionGroup>
                </List> :
                <List dense={true}>
                    <ListItem
                        sx={{
                            backgroundColor: 'darkgreen',
                            marginBottom: '5px',
                        }}
                    >
                        <ListItemAvatar><Error /></ListItemAvatar>
                        <ListItemText
                            primary="You haven't followed any stocks yet."
                            secondary={<i>Search to add stocks to your list!</i>}
                        />
                    </ListItem>
                </List>
        );
    }
}

export default SavedTickers;
import { User } from 'firebase/auth';
import { collection, CollectionReference, doc, DocumentData, getDoc, getDocs, limit, query, setDoc, Timestamp, where } from 'firebase/firestore/lite';

import moment from 'moment';

export interface Ticker {
    ticker: string,
    name: string,
}

export interface Favorites {
    tickers: string[],
    alerting: string[],
}

interface Earnings {
    earningsTime: Timestamp,
    lastUpdatedTime: Timestamp,
    quarter: number,
    year: number,
}

function docToTicker(doc: DocumentData) {
    const data = doc.data();
    return {
        ticker: data['ticker'],
        name: data['name'],
    } as Ticker;
}

export async function getTickersMatchingPrefix(db: any, prefix: string): Promise<Ticker[]> {
    prefix = prefix.toLowerCase();
    const capitalized = prefix[0].toUpperCase() + prefix.slice(1);
    const tickersCollection = collection(db, 'tickers');
    const byNameSnapshotPromise = getDocs(
        query(tickersCollection,
            where('name', '>=', capitalized),
            where('name', '<', `${capitalized}\uf8ff`),
            limit(10)));
    const byTickerSnapshotPromise = getDocs(
        query(tickersCollection,
            where('ticker', '>=', prefix),
            where('ticker', '<', `${prefix}\uf8ff`),
            limit(10)));

    const results = await Promise.all([byNameSnapshotPromise, byTickerSnapshotPromise]);
    const byNameList = results[0].docs.map(docToTicker);
    const byTickerList = results[1].docs.map(docToTicker);

    // Sort through both lists.
    const tickerList = byNameList.concat(byTickerList);
    tickerList.sort((a: any, b: any) => {
        if (a.name > b.name) {
            return 1;
        }
        return -1;
    });
    // TODO: Figure out why this list can have dupes.
    const tracker = new Set<string>();
    return tickerList.filter(value => {
        if (tracker.has(value.ticker)) {
            return false;
        }
        tracker.add(value.ticker);
        return true;
    });
}

async function lookupTicker(db: any, tickerCode: string) {
    const tickersCollection = collection(db, 'tickers') as CollectionReference<Ticker>;
    const resultSnapshot = await getDocs(query(tickersCollection,
        where('ticker', '==', tickerCode)));
    if (resultSnapshot.empty) {
        console.error('Ticker', tickerCode, 'no longer exists!');
        return null;
    }
    const res = resultSnapshot.docs.at(0);
    if (res) {
        return res.data();
    }
    return null;
}

export async function lookupTickers(db: any, tickerCodes: string[]) {
    return (await Promise.all(tickerCodes.map(tickerCode => lookupTicker(db, tickerCode)))).filter(v => !!v) as Ticker[];
}

export async function getLatestEarningsDate(
    db: any,
    ticker: string): Promise<moment.Moment | null> {
    const now = new Date();
    const thirtyDaysAgo = new Date(now);
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);
    const myQuery = query(
        collection(db, 'earnings_resolved'),
        where('ticker', '==', ticker),
        where('earningsTime', '>', Timestamp.fromDate(thirtyDaysAgo)));
    const resultSnapshot = await getDocs(myQuery);
    if (resultSnapshot.empty) {
        console.log('empty');
        return null;
    }
    const earnings: Earnings[] = resultSnapshot.docs.map(doc => doc.data() as Earnings).sort((
        a, b) => {
        // Ensure anything with date earlier than today is pushed to end of list.
        if (a.earningsTime.toDate() < now) {
            return 1;
        }
        if (b.earningsTime.toDate() < now) {
            return -1;
        }
        return a.earningsTime.seconds - b.earningsTime.seconds
    });
    return moment(earnings[0].earningsTime.toMillis());
}

export async function getFavorites(db: any, currentUser: User): Promise<Favorites> {
    const docRef = doc(db, 'favorites', currentUser.uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return docSnap.data() as Favorites;
    }
    return { tickers: [], alerting: [] };
}


export async function setFavorites(
    db: any,
    currentUser: User,
    favorites: Favorites) {
    const docRef = doc(db, 'favorites', currentUser.uid);
    await setDoc(docRef, favorites);
}